module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/builds/warsteiner/frontend/src/components/common/Layout/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-graphql-preview/gatsby-browser.js'),
      options: {"plugins":[],"fieldName":"craft","typeName":"Craft","url":"https://cms.warsteiner.de/api","headers":{"Authorization":"bearer hVj_7Z5i0X5xj0mvBMmHtLNvINRyrAPSJ1OirNbfdElE_nSGdns8vXT8hPDSvvAW"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"","includeInDevelopment":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
